class ScrollAnimate extends HTMLElement {
    constructor() {
        super()
        this.throttleDelay = 20

        this.style.setProperty('--delay', `${this.throttleDelay}ms`)
        this.style.setProperty('--scroll', this.offset())

        this.throttledScroll = this.throttle(
            this.scroll.bind(this),
            this.throttleDelay
        )
    }

    connectedCallback() {
        this.observer = new IntersectionObserver(
            (entries) => {
                for (const entry of entries) {
                    this.isVisible = entry.isIntersecting
                }
            },
            { root: null, rootMargin: '0px', threshold: 0 }
        )
        this.observer.observe(this)
        window.addEventListener('scroll', this.throttledScroll)
    }

    disconnectedCallback() {
        window.removeEventListener('scroll', this.throttledScroll)
        this.observer.unobserve(this)
    }

    scroll() {
        if (this.isVisible) {
            this.style.setProperty('--scroll', this.offset())
        }
    }

    offset() {
        const { top, height } = this.getBoundingClientRect()
        const min = -1 * height
        const max = window.innerHeight
        if (top >= max) {
            return 0
        } else if (top <= min) {
            return 1
        } else {
            return Math.floor(
                ((max + height - (top + height)) / (max - min)) * 100
            )
        }
    }

    throttle(func, delay) {
        let timer = null
        return () => {
            const _this = this
            const args = []
            for (let _len = arguments.length, _key = 0; _key < _len; _key++) {
                args[_key] = arguments[_key]
            }
            if (timer === null) {
                timer = setTimeout(() => {
                    func.apply(_this, args)
                    timer = null
                }, delay)
            }
        }
    }
}

customElements.define('scroll-animate', ScrollAnimate)
