import { throttle } from './common'

// detection du scroll de la page (throttled)
function scrolled() {
    this._st = window.pageYOffset || document.documentElement.scrollTop
    this._last = this._last || 0
    document.body.classList.toggle('scrolled', this._st > 1)
    this._last = this._st < 0 ? 0 : this._st
}

window.addEventListener('scroll', throttle(scrolled, 100))

scrolled()
