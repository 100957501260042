const form = document.querySelector('.contact .form')
let messageEl

if (form) {
    const submit = form.querySelector('.submit')
    messageEl = form.querySelector('.message')
    submit.addEventListener('click', async () => {
        if (!form.querySelector('.rgpd').checked) {
            message("Veuillez accepter les conditions d'utilisation")
            return
        }

        submit.disabled = true
        message('Envoi en cours...')

        const data = {
            token: form.querySelector('.token').value,
            nom: form.querySelector('.nom').value,
            prenom: form.querySelector('.prenom').value,
            email: form.querySelector('.email').value,
            telephone: form.querySelector('.telephone').value,
            projet: form.querySelector('.projet').value,
            commentaire: form.querySelector('.commentaire').value,
            rgpd: form.querySelector('.rgpd').checked,
        }

        const response = await fetch('/send.php', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })

        submit.disabled = false
        console.log(response)
        if (!response.ok) {
            message(response.statusText)
            return
        }

        const json = await response.json()
        console.log(json)
        if (json.error) {
            message(json.error)
        } else {
            message('Votre message a été envoyé.')
            form.querySelector('.nom').value = ''
            form.querySelector('.prenom').value = ''
            form.querySelector('.email').value = ''
            form.querySelector('.telephone').value = ''
            form.querySelector('.projet').value = ''
            form.querySelector('.commentaire').value = ''
            form.querySelector('.rgpd').checked = false
        }
    })
}

function message(text) {
    messageEl.innerHTML = text
}
