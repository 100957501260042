import { tns } from 'tiny-slider'

console.log(tns)
const wrapSliders = document.querySelectorAll('.wrap-slider')
for (const wrapSlider of wrapSliders) {
    const container = wrapSlider.querySelector('.slider')
    const prevButton = wrapSlider.querySelector('.prev')
    const nextButton = wrapSlider.querySelector('.next')
    const navContainer = wrapSlider.querySelector('nav')
    tns({
        container,
        prevButton,
        nextButton,
        navContainer,
        loop: false,
        mouseDrag: true,
    })
}
