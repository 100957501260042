const dialogMentions = document.querySelector('dialog.mentions')

if (dialogMentions) {
    // open
    document
        .querySelector('footer .mentions')
        .addEventListener('click', (e) => {
            e.preventDefault()
            dialogMentions.showModal()
        })

    // close with backdrop
    dialogMentions.addEventListener('click', (event) => {
        if (event.target === dialogMentions) {
            dialogMentions.close()
        }
    })

    // close with button
    dialogMentions
        .querySelector('.close-button')
        .addEventListener('click', (e) => {
            e.preventDefault()
            dialogMentions.close()
        })
}
