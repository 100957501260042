// Wait for n milliseconds (promise)
export function wait(ms) {
    return new Promise(function (r) {
        return setTimeout(r, ms)
    })
}

// Throttle
export function throttle(func, delay) {
    let timer = null
    return function () {
        const _this = this
        const args = []
        for (let _len = arguments.length, _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key]
        }
        if (timer === null) {
            timer = setTimeout(function () {
                func.apply(_this, args)
                timer = null
            }, delay)
        }
    }
}

// Traitement des chaines de caractères pour la recherche
// (suppression des diacritics + minuscule)
export function normalizeString(str) {
    return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
}

// Decimal round (half away from zero)
export function round(num, decimalPlaces) {
    var p = Math.pow(10, decimalPlaces || 0)
    var n = num * p * (1 + Number.EPSILON)
    return Math.round(n) / p
}

// Decimal ceil
export function ceil(num, decimalPlaces) {
    var p = Math.pow(10, decimalPlaces || 0)
    var n = num * p * (1 - Math.sign(num) * Number.EPSILON)
    return Math.ceil(n) / p
}

// Decimal floor
export function floor(num, decimalPlaces) {
    var p = Math.pow(10, decimalPlaces || 0)
    var n = num * p * (1 + Math.sign(num) * Number.EPSILON)
    return Math.floor(n) / p
}

// Decimal trunc
export function trunc(num, decimalPlaces) {
    return (num < 0 ? ceil : floor)(num, decimalPlaces || 0)
}

// Format using fixed-point notation
export function toFixed(num, decimalPlaces) {
    if (num === null) return null
    return round(num, decimalPlaces || 0)
        .toFixed(decimalPlaces || 0)
        .replace(/\./, ',')
}

export function toPercent(num, decimalPlaces) {
    if (num === null) return null
    return new Intl.NumberFormat('fr-FR', {
        style: 'percent',
        minimumFractionDigits: decimalPlaces || 0,
        maximumFractionDigits: decimalPlaces || 0,
    }).format(num)
}

export function toEuro(num, decimalPlaces) {
    if (num === null) return null
    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: decimalPlaces || 0,
        maximumFractionDigits: decimalPlaces || 0,
    }).format(num)
}

export function pick(obj, ...keys) {
    return Object.fromEntries(
        keys.filter((key) => key in obj).map((key) => [key, obj[key]]),
    )
}

export function inclusivePick(obj, ...keys) {
    return Object.fromEntries(keys.map((key) => [key, obj[key]]))
}

export function omit(obj, ...keys) {
    return Object.fromEntries(
        Object.entries(obj).filter(([key]) => !keys.includes(key)),
    )
}
